import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";

import ThemeProvider from './theme';
import { RPP_VERSION } from './config';
import './index.css';

const RedirectToWF = lazy(() => import('./handlers/RedirectToWF'));
const ResolveSlug = lazy(() => import('./handlers/ResolveSlug'));
const ResolveGroupSlug = lazy(() => import('./handlers/ResolveGroupSlug'));
const ProcessInvitationChoice = lazy(() => import('./handlers/ProcessInvitationChoice'));
const ProcessQRCode = lazy(() => import('./handlers/ProcessQRCode'));
const GoogleSmartWrite = lazy(() => import('./handlers/GoogleSmartWrite'));
const VerifyReview = lazy(() => import('./handlers/VerifyReview'));

const InfoPage = lazy(() => import('./pages/InfoPage'));
const InfoPagePreview = lazy(() => import('./pages/InfoPagePreview'));
const InfoPageThanksCallback = lazy(() => import('./pages/InfoPageThanksCallback'));
const InfoPageThanksAppointment = lazy(() => import('./pages/InfoPageThanksAppointment'));

const ErrorPage = lazy(() => import('./pages/ErrorPage'));
const UnsubscribeEmailPage = lazy(() => import('./pages/UnsubscribeEmailPage'));
const UnsubscribeSMSPage = lazy(() => import('./pages/UnsubscribeSMSPage'));

Bugsnag.start({ apiKey: '397b861fb70d67db1d1bd9f73a86f2f3', plugins: [new BugsnagPluginReact()] });
BugsnagPerformance.start({ apiKey: '397b861fb70d67db1d1bd9f73a86f2f3', appVersion: RPP_VERSION });

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ErrorBoundary>
    <ThemeProvider>
      <BrowserRouter>  
        <Suspense fallback={<></>}>
          <Routes>
            <Route path="/" element={<RedirectToWF />} />
            <Route path="/invite/by-hash/:criteria/oo" element={<UnsubscribeEmailPage />} />
            <Route path="/invite/by-invitecode/:criteria/oo" element={<UnsubscribeSMSPage />} />
            <Route path="/invite/by-:type/:criteria" element={<ProcessInvitationChoice />} />
            <Route path="/verify/:id/:hash" element={<VerifyReview />} />
            <Route path="/unsubscribe/email" element={<UnsubscribeEmailPage />} />
            <Route path="/unsubscribe/sms" element={<UnsubscribeSMSPage />} />
            <Route path="/write/gs/:companyID" element={<GoogleSmartWrite />} />
            <Route path="/write/*" element={<ResolveSlug writePage={true} />} />
            <Route path="/qrcode/:id" element={<ProcessQRCode />} />
            <Route path="/group/*" element={<ResolveGroupSlug />} />
            <Route path="/write/gs/:companyID" element={<GoogleSmartWrite />} />
            <Route path="/info-page/preview/:csID" element={<InfoPagePreview />} />
            <Route path="/info-page/:reviewID/:csID" element={<InfoPage />} />            
            <Route path="/info-page/thanks/callback" element={<InfoPageThanksCallback /> } />
            <Route path="/info-page/thanks/appointment" element={<InfoPageThanksAppointment /> } />
            <Route path="/*" element={<ResolveSlug writePage={false} />} />
            <Route path="*" element={<ErrorPage content="404 Not Found" />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  </ErrorBoundary>
);